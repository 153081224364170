@import "node_modules/bootstrap/scss/nav"
@import "node_modules/bootstrap/scss/navbar"

.dropdown-item
    &:active
        background-color: $white !important

nav.navbar
    z-index: 3
    padding-right: 2%
    padding-left: 2%
    border-radius: 0 0 15px 15px
    width: 90%
    margin: 0 auto
    .nav-item
        .nav-link
            font-size: 15px
            @media only screen and (min-width: 1230px)
                font-size: 14px
            @media only screen and (min-width: 1400px)
                font-size: 16px
        ul
            padding: 0px
            li
                border-bottom: 1px solid #9b999f
                &:last-child
                    border: none
                &:active
                    background-color: red !important
    .brand-logo
        left: 50%
        transform: translateX(-50%)
        font-size: unset
        height: 150px
        .logo-box
            z-index: 1
            margin: 0
            transition: all 0.7s ease
            display: block
            position: absolute
            left: 50%
            transform: translateX(-50%)
            @media only screen and (min-width: 993px)
                width: calc(200px)
                height: calc(200px)
                top: calc(-90px / 2)
            @media only screen and (min-width: 1280px)
                width: calc(220px)
                height: calc(220px)
                top: calc(-125px / 2)
            @media only screen and (min-width: 1440px)
                width: calc(230px)
                height: calc(230px)
                top: calc(-125px / 2)
            @media only screen and (min-width: 1920px)
                width: calc(230px)
                height: calc(230px)
                top: calc(-125px / 2)
            .box
                background-color: $amarillo
                width: 100%
                height: 100%
                border-radius: 0 0 30% 0
                transform: rotate(45deg)

        .logo
            width: 75px
            max-width: none
            top: 45px
            object-fit: contain
            position: absolute
            left: 50%
            transform: translateX(-50%)
            z-index: 2
            @media only screen and (min-width: 993px)
                width: calc(120px)
                bottom: 40px
            @media only screen and (min-width: 1280px)
                width: calc(120px)
                bottom: 40px
            @media only screen and (min-width: 1440px)
                width: calc(120px)
                bottom: 40px
            @media only screen and (min-width: 1920px)
                width: calc(130px)
                bottom: 40px
    .flag
        height: 24px
        width: 24px
    .monedas
        width: 700px
        .moneda
            cursor: pointer
            text-decoration: none
    .hover-menu
        &:hover
            background-color: $azul-oscuro2 !important
            color: $white !important
    .moneda-selected
        background-color: $azul-oscuro2 !important
        color: $white !important

#llamanos, #centro-ayuda
    width: 300px
    .horarios
        background-color: $amarillo

#login,
#get-reserva
    width: 300px

.content-login
    display: flex
    width: 600px

#get-reserva
    .captcha
        transform: scale(0.8)
