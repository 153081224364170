
@import 'colores.sass'

@import "fuentes.sass"

/** CUSTOM BOOSTRAP **/
@import '../librerias/boostrap-custom.sass'

/** ICONOS DE ALKILAUTOS.COM **/
@import "../assets/fonts/fonts-alk-ico/style.css"

/** MENU **/
@import 'nav.sass'

/** BOTONES **/
@import "buttons.sass"

/** HEADER **/
@import "header.sass"

@import 'footer.sass'

body
    font-family: 'config_roundedlight' !important
    overflow-x: hidden
    a, .text
        color: $azul-oscuro
    .border-bottom-radius
        border-bottom-left-radius: 5px
        border-bottom-right-radius: 5px
    .breadcrumb
        .breadcrumb-item
            a
                text-decoration: none
                color: $azul-oscuro2
    .text-justify
        text-align: justify
        text-justify: inter-word
    .form-control
        border-radius: 10px !important
    .btn.disabled
        color: $white
        opacity: 0.3
    .modal-content
        background-color: $white
        border-radius: 20px
    // Haz que el modal ocupe toda la pantalla
    .modal-fullscreen
        width: 100%
        height: 100%
        margin: 0
        display: inline-table
    // Ajusta el contenido del modal para ocupar toda la pantalla
    .modal-content
        border: 0
        border-radius: 0
    .modal-lg
        max-width: 80%
        width: 80%
        margin-left: 10%
    .btn-success
        //font-size: 22px !important
        font-weight: bold

    .form-control-feedback
        color: #dc3545
    #enviar-cotizacion
        .form-control-feedback
            color: $white !important
    .input-invalid
        border-color: #dc3545
    ul
        list-style-type: none
    .oculto
        display: none

    .form-floating
        input::placeholder
            font-size: 5px !important
    .form-floating
        label
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            max-width: 100%
            font-size: 15px
            @media only screen and (min-width: 1600px)
                font-size: 15px

::-webkit-scrollbar
    width: 20px
::-webkit-scrollbar-track
    background-color: transparent
::-webkit-scrollbar-thumb
    background-color: #d6dee1
    border-radius: 20px
    border: 6px solid transparent
    background-clip: content-box
    &:hover
        background-color: #a8bbbf
.modal-dialog
    margin-top: 10%
    border-radius: 20px !important
    .title-modal
        border-top-left-radius: 20px
        border-top-right-radius: 20px
.modal-dialog.modal-fullscreen
    margin-top: 0%
    border-radius: 0px !important

.container
    @media only screen and (min-width: 1100px)
        max-width: 1048px
    @media only screen and (min-width: 1200px)
        max-width: 1124px
    @media only screen and (min-width: 1300px)
        max-width: 1224px
    @media only screen and (min-width: 1400px)
        max-width: 1324px
    @media only screen and (min-width: 1500px)
        max-width: 1424px

#cookies
    position: fixed
    bottom: 0
    width: 100%
    height: 110px
    padding-bottom: 16px
    background-color: $amarillo
    z-index: 2000
    color: $azul-oscuro3
    padding: 20px
    font-size: 16px
    margin-bottom: 0px !important
    display: none
    padding: 30px
    @media only screen and (min-width: 1200px)
        font-size: 17px
    @media only screen and (min-width: 1400px)
        font-size: 18px
    #aceptar-cookies
        width: 80%
.form-check-input[type=checkbox]
    border-color: $gris-oscuro

.input-clear-button
    float: right
    margin-top: -25px
    margin-right: 5px
    font-size: 1.3rem
    cursor: pointer
    z-index: 2
    position: relative
    display: none

.banner-promo
    z-index: 9999
    position: relative
    img
        user-select: none
        -webkit-user-drag: none
    i
        position: absolute
        cursor: pointer
        top: 50%
        right: 10px
        transform: translateY(-50%)
