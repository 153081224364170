/** FUENTES **/
@font-face
    font-family: 'config_roundedtext'
    src: url('../assets/fonts/config-rounded/configrounded-text-webfont.woff2') format('woff2'),
    src: url('../assets/fonts/config-rounded/configrounded-text-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'config_roundedlight'
    src: url('../assets/fonts/config-rounded/configrounded-light-webfont.woff2') format('woff2'),
    src: url('../assets/fonts/config-rounded/configrounded-light-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'config_roundedextralight'
    src: url('../assets/fonts/config-rounded/configrounded-extralight-webfont.woff2') format('woff2'),
    src: url('../assets/fonts/config-rounded/configrounded-extralight-webfont.woff') format('woff')
    font-weight: normal
    font-style: normal
    font-display: swap 