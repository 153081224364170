//Btn Success
.btn-success
    color: #003da6
    border-radius: 14px
    .spinner-border
        color: #003da6 !important
    &:hover
        color: #003da6
.btn-circle.btn-lg
    width: 50px
    height: 50px
    padding: 10px 16px
    font-size: 18px
    line-height: 1.33
    border-radius: 25px