@import 'include/base.sass'
@import 'include/iconos.sass'

body
    background-image: url(/public/img/bg-azul.webp)
    height: 100vh
    background-size: cover
main
    padding-top: 100px
.bg
    min-height: 0px !important
    background: none
h1
    font-size: 120px
    font-weight: bold
h2
    font-size: 80px
    font-weight: bold
.img-error
    margin-top: -250px
.volver
    margin-top: -100px