//$form-select-indicator-color: red
#h-buscador
    position: relative
    min-height: 300px
    /** BOTON BUSCAR
    #buscar
        font-size: 22px
        width: 200px
        @media only screen and (min-width: 1400px)
            width: 300px
    .bg-box
        display: none
        @media only screen and (min-width: 993px)
            display: block
            width: 400px
            height: 400px
            border-radius: 0 0 30% 0
            transform: rotate(45deg) !important
            transition: all 0.7s ease
            position: absolute
            top: -244px
            right: 105px
            overflow: hidden
            object-fit: contain
        .inner-div
            display: block
            background-image: url('/public/img/bg_city.webp')
            background-size: contain
            background-repeat: no-repeat
            width: 550px
            height: 450px
            transform: rotate(-45deg) !important
            position: absolute
            top: 150px
            right: -280px
    .titulo
        padding-top: 80px
        @media only screen and (min-width: 1200px)
            padding-top: 70px
        @media only screen and (min-width: 1300px)
            padding-top: 100px
        color: $white
        width: 70%
    .switch
        .form-switch
            label
                cursor: pointer
                color: $white
            input
                cursor: pointer
        .form-check-input:checked
            background-color: $amarillo
            border-color: 5px $white
        .form-check-input
            border: 3px solid $white
    .cresidente
        color: $white
        cursor: pointer
        // Estilo personalizado para el form-select
        .form-select
            // Cambiar el color de la flecha hacia abajo
            &.btn
                &::after
                color: red // Cambia el color a tu preferencia
        select
            background-color: transparent
            color: $amarillo
            border: none
            cursor: pointer
            width: 25%
            background-image: url('/public/img/angle-down.svg')
            background-position: calc(100% - 30px) 14px, calc(100% - 20px) 14px, 100% 0
            background-size: 10px 10px, 10px 10px
            background-repeat: no-repeat
            -webkit-appearance: none
            -moz-appearance: none
            option
                color: $azul-oscuro2
                cursor: pointer
            
    .input-code-prom
        input
            background-color: transparent
            color: $white
            border: none
            border-bottom: 1px solid $amarillo
            width: 113%
            @media only screen and (min-width: 1100px)
                width: 100%
            @media only screen and (min-width: 1400px)
                width: 95%

            &:focus-visible
                border: none
                outline: none
                border-bottom: 1px solid $amarillo
            &::placeholder
                color: $white
.input-text
    background-color: $white
    padding: 5px
    padding-left: 10px
    height: 60px
    width: 100%
    border-radius: 10px
    .row
        margin: 0px !important
    label
        font-size: 11px
        @media only screen and (min-width: 1400px)
            font-size: 12px
        color: $gris
    input
        width: 100%
        display: block
        border: none
        color: $texto 
        font-weight: bold
        &:focus-visible
            border: none
            outline: none
.fecha-hora-container
    width: 100% !important
    @media only screen and (min-width: 1200px)
        width: 45% !important
    .fecha-container
        border-right: 2px $gris-claro-plus solid
    .hora-container
        cursor: pointer
        input
            cursor: pointer

.tooltip-search
    z-index: 99999
    --bs-tooltip-bg: #{$rojo-btn-hover}
    --bs-tooltip-color: var(--bs-white)
