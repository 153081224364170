$blanco 		    :#f2f2f2
$azul-oscuro 	    :#03207B
$azul-oscuro2 	    :#01369c
$azul-oscuro3       :#03207B
$azul               :#0042C2
$azul-claro         :#E3E9FF
$azul-claro-plus    :#eff0f8
$gris 			    :#666666
$gris-oscuro        :#535353
$gris-lineas 	    :#9b999f
$gris-claro 	    :#CAC9CD
$gris-claro-plus    :#E7E6E8
$gris-blanco        :#F3F3F3
$gris-ofertas       :#F3F2F2
$amarillo 		    :#fedd15
$amarillo-oscuro    :#EAAE18
$naranja            :#D2671D

$verde-btn 		    :#00FF1E 
$verte-btn-hover    :#26e794
$verde-card 	    :rgba(17, 206, 125, .8)
$white 	            :#FFFF


$rojo-btn		    :#f60c5b
$rojo-btn-hover     :#ef4981

$rosado             :#F53F7C

$morado             :#A700C1
$morado_renting     :#4103a6

$cian               :#03D6CF

$font-bold 		    :config_roundedtext
$font-normal 	    :config_roundedlight
$font-light 	    :config_roundedextralight

$texto              :rgba(0, 0, 0, 0.87)