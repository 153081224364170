@font-face
	font-family 'icomoon'
	src	url('/public/fonts/custom_icons/icomoon.eot?8lay5b')
	src	url('/public/fonts/custom_icons/icomoon.eot?8lay5b#iefix') format('embedded-opentype'),
		url('/public/fonts/custom_icons/icomoon.ttf?8lay5b') format('truetype'),
		url('/public/fonts/custom_icons/icomoon.woff?8lay5b') format('woff'),
		url('/public/fonts/custom_icons/icomoon.svg?8lay5b#icomoon') format('svg')
	font-weight normal
	font-style normal
	font-display swap

i.custom
	font-family 'icomoon' !important
	speak none
	font-style normal
	font-weight normal
	font-variant normal
	text-transform none
	line-height 1
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale

.icon-llama:before
	content "\e900"

.icon-carro-herramienta:before
	content "\e901"

.icon-tacometro:before
	content "\e902"

.icon-usuarios:before
	content "\e903"

.icon-maletas:before
	content "\e904"

.icon-puertas:before
	content "\e905"

.icon-ventilador:before
	content "\e906"

.icon-avion:before
	content "\e908"
	color #003da6

.icon-facebook:before
	content "\e90a"

.icon-gmail:before
	content "\e90b"

.icon-email:before
	content "\e90c"

.icon-flag:before
	content "\e90d"

.icon-sol:before
	content "\e90e"
	color #003da6

.icon-corazon:before
	content "\e90f"
	color #003da6

.icon-caja:before
	content "\e910"

.icon-reloj:before
	content "\e912"

.icon-rayo:before
	content "\e914"

.icon-bus:before
	content "\e915"

.icon-edificio:before
	content "\e916"

.icon-usuario-pancarta:before
	content "\e917"

.icon-no-dinero:before
	content "\e918"
	color #f3d429

.icon-engrane:before
	content "\e919"
	color #f3d429

.icon-refrescar-reloj:before
	content "\e930"
	color #003da6

.icon-salir:before
	content "\e931"
	color #003da6

.icon-refrescar:before
	content "\e943"
	color #003da6

.icon-costo-total:before
	content "\e949"

.icon-salir-rombo:before
	content "\e94b"
	color #003da6

.icon-globo2:before
	content "\e94c"
	color #003da6

.icon-globo-dinero:before
	content "\e94d"
	color #003da6

.icon-calendario-lleno:before
	content "\e94f"
	color #003da6

.icon-calendario:before
	content "\e950"
	color #003da6

.icon-reloj2:before
	content "\e951"
	color #003da6

.icon-llamada2:before
	content "\e909"
	color #fff

.icon-regalo:before
	content "\e957"
	color #003da6

.icon-corona:before
	content "\e958"
	color #003da6

.icon-premio:before
	content "\e959"
	color #003da6

.icon-ubicacion:before
	content "\e95a"
	color #003da6

.icon-atras:before
	content "\e95b"
	color #003da6

.icon-adelante:before
	content "\e95c"
	color #003da6

.icon-trofeo .path1:before
	content "\e95d"
	color rgb(0, 61, 166)

.icon-trofeo .path2:before
	content "\e95e"
	margin-left -1em
	color rgb(243, 212, 41)

.icon-trofeo .path3:before
	content "\e95f"
	margin-left -1em
	color rgb(243, 212, 41)

.icon-trofeo .path4:before
	content "\e960"
	margin-left -1em
	color rgb(243, 212, 41)

.icon-trofeo .path5:before
	content "\e961"
	margin-left -1em
	color rgb(243, 212, 41)

.icon-trofeo .path6:before
	content "\e962"
	margin-left -1em
	color rgb(243, 212, 41)

.icon-timon:before
	content "\e96e"

.icon-otro-conductor:before
	content "\e96f"

.icon-franja2 .path1:before
	content "\e972"
	color rgb(0, 0, 0)

.icon-franja2 .path2:before
	content "\e973"
	margin-left -2.6162109375em
	color rgb(0, 0, 0)

.icon-franja2 .path3:before
	content "\e974"
	margin-left -2.6162109375em
	color rgb(246, 12, 91)

.icon-franja2 .path4:before
	content "\e975"
	margin-left -2.6162109375em
	color rgb(246, 12, 91)

.icon-franja2 .path5:before
	content "\e976"
	margin-left -2.6162109375em
	color rgb(246, 12, 91)

.icon-franja3 .path1:before
	content "\e977"
	color rgb(0, 0, 0)

.icon-franja3 .path2:before
	content "\e978"
	margin-left -2.7861328125em
	color rgb(246, 12, 91)

.icon-usuaria-circulo .path1:before
	content "\e91b"
	color rgb(0, 0, 0)

.icon-usuaria-circulo .path2:before
	content "\e91c"
	margin-left -1em
	color rgb(0, 0, 0)

.icon-usuaria-circulo .path3:before
	content "\e91d"
	margin-left -1em
	color rgb(0, 0, 0)

.icon-usuaria-circulo .path4:before
	content "\e91e"
	margin-left -1em
	color rgb(0, 0, 0)

.icon-usuaria-circulo .path5:before
	content "\e91f"
	margin-left -1em
	color rgb(255, 255, 255)

.icon-usuaria-circulo .path6:before
	content "\e920"
	margin-left -1em
	color rgb(0, 0, 0)

.icon-usuario-pelo .path1:before
	content "\e927"
	color rgb(0, 0, 0)

.icon-usuario-pelo .path2:before
	content "\e928"
	margin-left -1em
	color rgb(0, 0, 0)

.icon-usuario-pelo .path3:before
	content "\e929"
	margin-left -1em
	color rgb(0, 0, 0)

.icon-usuario-pelo .path4:before
	content "\e92a"
	margin-left -1em
	color rgb(255, 255, 255)

.icon-usuario-pelo .path5:before
	content "\e92b"
	margin-left -1em
	color rgb(0, 0, 0)
