@font-face {
  font-family: 'alkilautos';
  src:  url('fonts/alkilautos.eot?ox08ra');
  src:  url('fonts/alkilautos.eot?ox08ra#iefix') format('embedded-opentype'),
    url('fonts/alkilautos.ttf?ox08ra') format('truetype'),
    url('fonts/alkilautos.woff?ox08ra') format('woff'),
    url('fonts/alkilautos.svg?ox08ra#alkilautos') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.alk {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'alkilautos' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alk-headset:before {
  content: "\e961";
}
.alk-shield-check:before {
  content: "\e960";
}
.alk-question-o:before {
  content: "\e95f";
}
.alk-file-list-o:before {
  content: "\e95e";
}
.alk-car-star-o:before {
  content: "\e95d";
}
.alk-buildings:before {
  content: "\e95c";
}
.alk-city:before {
  content: "\e95c";
}
.alk-truck:before {
  content: "\e959";
}
.alk-transit:before {
  content: "\e959";
}
.alk-transport:before {
  content: "\e959";
}
.alk-delivery:before {
  content: "\e959";
}
.alk-domicilio:before {
  content: "\e959";
}
.alk-child-friendly:before {
  content: "\e95a";
}
.alk-ad1:before {
  content: "\e95a";
}
.alk-whatsapp:before {
  content: "\e95b";
}
.alk-car-wash:before {
  content: "\e958";
}
.alk-ad3:before {
  content: "\e958";
}
.alk-close:before {
  content: "\e956";
}
.alk-pencil:before {
  content: "\e957";
}
.alk-edit:before {
  content: "\e957";
}
.alk-radio-button-on:before {
  content: "\e93b";
}
.alk-radio-button-off:before {
  content: "\e93c";
}
.alk-highlight-remove:before {
  content: "\e955";
}
.alk-x-xmark:before {
  content: "\e939";
}
.alk-facebook:before {
  content: "\e906";
}
.alk-facebook-o:before {
  content: "\e90d";
}
.alk-google:before {
  content: "\e925";
}
.alk-heart:before {
  content: "\e903";
}
.alk-heart-o:before {
  content: "\e915";
}
.alk-instagram:before {
  content: "\e94c";
}
.alk-linkedin:before {
  content: "\e94a";
}
.alk-plane:before {
  content: "\e905";
}
.alk-twitter:before {
  content: "\e94b";
}
.alk-car-shield:before {
  content: "\e941";
}
.alk-ad6:before {
  content: "\e941";
}
.alk-driver-user:before {
  content: "\e942";
}
.alk-ad4:before {
  content: "\e942";
}
.alk-compass:before {
  content: "\e943";
}
.alk-ad2:before {
  content: "\e943";
}
.alk-local-taxes:before {
  content: "\e944";
}
.alk-impuestos-locales:before {
  content: "\e944";
}
.alk-oil-can:before {
  content: "\e945";
}
.alk-fas:before {
  content: "\e945";
}
.alk-car-user:before {
  content: "\e92e";
}
.alk-fire-circle:before {
  content: "\e92d";
}
.alk-gauge:before {
  content: "\e933";
}
.alk-checkbox-unchecked:before {
  content: "\e93d";
}
.alk-checkbox:before {
  content: "\e93d";
}
.alk-unchecked:before {
  content: "\e93d";
}
.alk-checkbox-checked:before {
  content: "\e940";
}
.alk-checked:before {
  content: "\e940";
}
.alk-selected:before {
  content: "\e940";
}
.alk-angle-down:before {
  content: "\e911";
}
.alk-angle-up:before {
  content: "\e912";
}
.alk-angle-left:before {
  content: "\e90f";
}
.alk-angle-right:before {
  content: "\e910";
}
.alk-car-wrench:before {
  content: "\e92a";
}
.alk-angles-left:before {
  content: "\e919";
}
.alk-angles-left-o:before {
  content: "\e927";
}
.alk-angles-right:before {
  content: "\e909";
}
.alk-angles-right-o:before {
  content: "\e91a";
}
.alk-building:before {
  content: "\e92f";
}
.alk-clock:before {
  content: "\e90c";
}
.alk-clock-rotate:before {
  content: "\e913";
}
.alk-envelope-circle:before {
  content: "\e921";
}
.alk-fan-moving:before {
  content: "\e93f";
}
.alk-i_aa:before {
  content: "\e93f";
}
.alk-phone:before {
  content: "\e935";
}
.alk-phone-circle:before {
  content: "\e934";
}
.alk-rotate:before {
  content: "\e922";
}
.alk-user-circle:before {
  content: "\f2bd";
}
.alk-smile:before {
  content: "\e94d";
}
.alk-check:before {
  content: "\e94e";
}
.alk-ban:before {
  content: "\e951";
}
.alk-star-menu:before {
  content: "\e946";
}
.alk-check-circle:before {
  content: "\f058";
}
.alk-award:before {
  content: "\e937";
}
.alk-bars:before {
  content: "\e926";
}
.alk-bolt:before {
  content: "\e904";
}
.alk-briefcase:before {
  content: "\e920";
}
.alk-i_maletero:before {
  content: "\e920";
}
.alk-calendar:before {
  content: "\e91e";
}
.alk-calendar-days:before {
  content: "\e936";
}
.alk-gift:before {
  content: "\e928";
}
.alk-location:before {
  content: "\e918";
}
.alk-timer:before {
  content: "\e90b";
}
.alk-star:before {
  content: "\f005";
}
.alk-star-o:before {
  content: "\f006";
}
.alk-tags:before {
  content: "\f02c";
}
.alk-star-half:before {
  content: "\f089";
}
.alk-tag-o:before {
  content: "\e949";
}
.alk-banner-flag:before {
  content: "\e902";
}
.alk-bus:before {
  content: "\e924";
}
.alk-car:before {
  content: "\e938";
}
.alk-car-open-door:before {
  content: "\e929";
}
.alk-i_puertas:before {
  content: "\e929";
}
.alk-crown:before {
  content: "\e917";
}
.alk-envelope:before {
  content: "\e91b";
}
.alk-exit:before {
  content: "\e91d";
}
.alk-exit-door:before {
  content: "\e90e";
}
.alk-file-price:before {
  content: "\e932";
}
.alk-flag:before {
  content: "\e916";
}
.alk-gear:before {
  content: "\e93e";
}
.alk-gearbox:before {
  content: "\e914";
}
.alk-i_tcaja:before {
  content: "\e914";
}
.alk-globe:before {
  content: "\e900";
}
.alk-list-horizontal:before {
  content: "\e907";
}
.alk-list-horizontal-o:before {
  content: "\e908";
}
.alk-list-square:before {
  content: "\e923";
}
.alk-list-square-o:before {
  content: "\e90a";
}
.alk-not-price:before {
  content: "\e92b";
}
.alk-person-flag:before {
  content: "\e931";
}
.alk-price:before {
  content: "\e93a";
}
.alk-steering-wheel:before {
  content: "\e91c";
}
.alk-sun:before {
  content: "\e930";
}
.alk-not-24h:before {
  content: "\e954";
}
.alk-cancelacion-24-hrs-antes:before {
  content: "\e954";
}
.alk-file-list:before {
  content: "\e950";
}
.alk-question:before {
  content: "\e952";
}
.alk-car-star:before {
  content: "\e953";
}
.alk-lock-alt:before {
  content: "\e94f";
}
.alk-google-letter:before {
  content: "\f1a0";
}
.alk-comment-dots:before {
  content: "\e948";
}
.alk-clipboard:before {
  content: "\e947";
}
.alk-globe-price:before {
  content: "\e92c";
}
.alk-user:before {
  content: "\e901";
}
.alk-ico4:before {
  content: "\e901";
}
.alk-users:before {
  content: "\e91f";
}
.alk-i_numpas:before {
  content: "\e91f";
}
.alk-info:before {
  content: "\ea0c";
}
