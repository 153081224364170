footer
    color: $white
    .button-down
        margin-top: -20px
    .footer-titles
        border-bottom: 1px solid $white
        margin-bottom: 10px
        .text-title
            padding-left: 30%
    ul
        padding-left: 30%
        li
            list-style: none
            a
                text-decoration: none
                color: $white
    .redes-f
        span
            margin: 0px
        .redes
            margin-top: 10px
        .redes-footer
            img
                width: 35px
                height: 35px
                margin: 5px
    .text-ayuda
        font-size: 12px
    .c-text-ayuda
        line-height: 1
    .select-domain
        width: 90%
    .top-ico
        z-index: 1
        cursor: pointer
