.flatpickr-months
    padding: 20px
    background-color: $amarillo
    border-top-left-radius: 20px
    border-top-right-radius: 20px
.flatpickr-months .flatpickr-month
    background-color: $amarillo
    color: $azul-oscuro
        
.flatpickr-current-month .flatpickr-monthDropdown-months
    display: inline-block
    background-color: $amarillo
    color: $azul-oscuro

.flatpickr-weekdays
    background-color: $white
    margin-top: 15px
        
span.flatpickr-weekday
    background-color: $white
    color: $gris-lineas
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month
    color: $azul-oscuro
    background-color: $amarillo
.flatpickr-months
    padding: 10px !important
/** flechas */
.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path
    fill: $azul
    font-weight: bold
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month
    top:1%
.flatpickr-months .flatpickr-prev-month
    left:3% !important
.flatpickr-months .flatpickr-next-month
    right:3% !important

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after
    border-bottom-color: $azul-oscuro

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after
    border-top-color: $azul-oscuro

.flatpickr-calendar.arrowTop.time:before, .flatpickr-calendar.arrowTop.time:after
    left: auto
    right: 22px
                
.flatpickr-calendar.arrowBottom.time:before, .flatpickr-calendar.arrowBottom.time:after
    left: auto
    right: 22px

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay
    background-color: $azul 
    border-color: $azul 
.flatpickr-day.inRange
    background-color: #E7EFFF
    -webkit-box-shadow: -5px 0 0 #E7EFFF, 5px 0 0 #E7EFFF
    border-color: #E7EFFF !important
.flatpickr-day.today
    border-color: $gris
.flatpickr-day.festivo
    border-color: $rojo-btn
.flatpickr-day
    color: $azul-oscuro 
        
.flatpickr-time
    display: none !important
        
.timepicker-content
    background-color: $amarillo
    color: $azul-oscuro
    margin: 0 auto
    width: 100%
    display: none
    border-radius: 20px
        
    .timepicker-head
        font-size: 16px            
        margin: 0
        padding: 2px 4px
        background-color: $amarillo
        color: $azul-oscuro
        font-weight: bold
        text-align: center
        height: 50px
        border-top-left-radius: 20px
        border-top-right-radius: 20px
        padding-top: 15px
        
    .volver
        cursor: pointer
        text-align: left
        display: flex
        
    .timepicker-cbody
        background-color: #FFF
        color: $gris
        padding-left: 10px
        padding-right: 10px
        padding-bottom: 10px
        border-bottom-left-radius: 20px
        border-bottom-right-radius: 20px
        box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px -1px rgba(0,0,0,0.08)
          
    .timepicker-body
        align-self: center
            
        .timepicker-title 
            color: $azul-oscuro
            font-weight: bold
            text-align: left
            margin: 4px 8px
                       
        .time 
            display: flex 
            flex-flow: row wrap
            justify-content: center                
            span 
                cursor: pointer
                border: 1px solid #EEE
                border-radius: 4px
                padding: 4px
                margin: 2px
                width: calc(16.66% - 4px)
                &:hover 
                    color: #FFF
                    background-color: $gris-lineas 
                    border: 1px solid transparent
                &.selected
                    color: #FFF
                    background-color: $azul
                    border: 1px solid $gris-lineas  
                &.disabled
                    cursor: not-allowed
                    color: $gris-lineas
.dayContainer
    margin-left: 45px
    margin-right: 47px
    margin-bottom: 20px
    border: none
    box-shadow: none !important
    //background-color: $amarillo
.flatpickr-weekdaycontainer
    margin-left: 50px
    margin-right: 50px
.cur-month,
.cur-year
    color: $azul !important
.flatpickr-innerContainer
    border-bottom-left-radius: 20px
    border-bottom-right-radius: 20px
.flatpickr-calendar.arrowTop
  &:before, &:after
    display: none !important
.flatpickr-calendar
    border-radius: 20px
    opacity: 0
.flatpickr
    cursor: pointer !important
.flatpickr-calendar
  &.open, &.inline
    opacity: 0
